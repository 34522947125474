import axios from "axios";
import { CallRecord } from '@/types/call_record';

export interface GetListParams {
  user_ids: number[];
  started_at: string;
  offset?: number;
}

export class CallRecordRepository {
  private url: string;

  constructor(project_id: number) {
    this.url = CallRecordRepository.url(project_id);
  }

  async get(id: number): Promise<CallRecord> {
    return axios.get(this.url + id).then((response): CallRecord => {
      return response.data;
    });
  }

  async getList(params: GetListParams): Promise<CallRecord[]> {
    return axios.get(this.url, { params: params }).then((response): CallRecord[] => {
      // convert
      return response.data.call_records;
    });
  }

  private static url(project_id: number) {
    return process.env.VUE_APP_DOMAIN + 'v1/projects/' + project_id + '/call_records/'
  }
}
