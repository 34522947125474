import { CallRecordRepository, GetListParams } from '@/repositories/CallRecordRepository'
import { UserRepository } from '@/repositories/UserRepository'
import { CallRecord } from '@/types/call_record'
import { User } from '@/types/user'
import axios from 'axios';
import { onMounted, ref, Ref, watch } from 'vue'
import { useRoute } from 'vue-router'

export const useCallRecords = () => {
  const route = useRoute();

  const callRecords: Ref<CallRecord[]> = ref<CallRecord[]>([]);
  const loading: Ref<boolean> = ref<boolean>(false);
  const noMore: Ref<boolean> = ref<boolean>(false);
  const errorMessage: Ref<string> = ref<string>('');
  const searchDate: Ref<string> =  ref<string>('');
  const searchUserIds: Ref<number[]> =  ref<number[]>([]);
  const users: Ref<User[]> = ref<User[]>([]);

  const handleChangeUserIds = (userIds: number[]): void => {
    searchUserIds.value = userIds;
    load(true);
  };
  const handleChangeDate = (date: string): void => {
    searchDate.value = date;
    load(true);
  };

  const load = async (clear: boolean = false) => {
    loading.value = true;

    if (clear) {
      callRecords.value = [];
      noMore.value = false;
    }

    try {
      const repository = new CallRecordRepository(Number(route.params.project_id));
      const params: GetListParams = {
        user_ids: searchUserIds.value,
        started_at: searchDate.value,
        offset: callRecords.value.length,
      };
      const list: CallRecord[] = await repository.getList(params);

      callRecords.value = callRecords.value.concat(list);

      if (list.length === 0) {
        noMore.value = true;
      }
      loading.value = false;
    } catch (e: unknown) {
      if (axios.isAxiosError(e) && e.response) {
        errorMessage.value = `モニタリング情報のロード時にエラーが発生しました。(status: ${e.response.status})`;
      }
    }
  };

  const loadUsers = async (): Promise<void> => {
    const userRepositroy = new UserRepository();
    users.value = await userRepositroy.getList({project_id: Number(route.params.project_id)});
  };

  watch(() => route.params.project_id, (): void => {
    if (route.params.project_id) {
      searchDate.value = '';
      searchUserIds.value = [];
      load(true);

      loadUsers();
    }
  });

  onMounted(() => {
    loadUsers();
  });

  return {
    callRecords,
    load,
    noMore,
    loading,
    errorMessage,
    handleChangeUserIds,
    handleChangeDate,
    users,
  }
};
